/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
}

h2 {
    color: #333;
}

.navbar-brand {
    font-weight: bold;
    color: #5941A9 !important;
}

.task {
    transition: all 0.15s ease-in-out;
}

.task:hover {
    background-color: #e9ecef;
}

button {
    transition: all 0.15s ease-in-out;
}

button:hover {
    transform: scale(1.05);
}

.modal-header, .modal-body {
    transition: all 0.15s ease-in-out;
}

/* Custom Toast for Notifications */
.toast {
    position: fixed;
    top: 1rem;
    right: 1rem;
    min-width: 200px;
    opacity: 0.9;
}

/* Fade in/out animations */
.fade {
    animation: fadeInOut 0.15s ease-in-out;
}

@keyframes fadeInOut {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .navbar-nav {
        text-align: center;
    }
}

.bg-primary {
    background-color: #5941A9 !important;
}

.btn-primary {
    background-color: #5941A9 !important;
    --bs-btn-border-color: #000 !important;
}

.push-button {
    background-color: #5941A9 !important;
}

a, a:visited {
    --bs-link-color-rgb: #5941A9 !important;
}

.btn-outline-primary {
    --bs-btn-color: #5941A9 !important;
    --bs-btn-border-color: #5941A9 !important;
    --bs-btn-hover-bg: #5941A9 !important;
    --bs-btn-hover-border-color: #5941A9 !important;
    --bs-btn-active-bg: #5941A9 !important;
    --bs-btn-active-border-color: #5941A9 !important;
}

.margin-bottom-200 {
    margin-bottom: 200px !important;
}

.shadow {
    box-shadow:  2px 2px rgba(0, 0, 0, 0.1) !important;
}

.rounded-border {
    border-radius: 8px;
}

.black-border {
    border: solid 1px black !important;
}
