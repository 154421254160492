/* FeedbackForm.css */
.container {
    max-width: 100%;
}

.feedback-form {
    margin-top: 5rem;
}

.feedback-form .card {
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feedback-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.feedback-form .form-label {
    font-weight: 500;
}

.feedback-form .btn-primary {
    background-color: #5941A9;
    border-color: #5941A9;
}

.feedback-form .btn-primary:hover {
    background-color: #5941A9;
    border-color: #5941A9;
}
