.coach-profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coach-profile {
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.avatar {
  width: 60px;
  height: 60px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.role {
  position: absolute;
  bottom: -10px;
  background-color: #333;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.coach-name {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px;
}

.coach-timezone,
.coach-education {
  font-size: 14px;
  color: #555;
}

.rating {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.star {
  font-size: 20px;
  color: #ccc;
  margin: 0 2px;
}

.reviews {
  margin-top: 20px;
}

.reviews h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.review {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.review-text {
  font-size: 14px;
  margin: 0 0 8px;
}

.review-author {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #777;
}

.review-author-name {
  font-weight: bold;
}

.review-date {
  font-size: 12px;
  color: #777;
}

.bio {

}

.bio-text {
  font-size: 14px;
  margin: 0 0 8px;
}

.contact-coach {
  display: flex;
  align-items: center;
  justify-content: center;
}
