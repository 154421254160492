.dashboard {
    margin-bottom: 100px !important;
}

.form-control:focus {
    border-color: #5941A9 !important;
    box-shadow: 0 0 0 .25rem rgba(89,65,169,.25) !important;
}

.searchbar {
    display: flex;
}

.cursor-pointer {
    cursor: pointer !important;
}
