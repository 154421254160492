.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
}

.project-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 300px;
}

.project-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.task-list {
  list-style: none;
  padding: 0;
}

.task-item {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  font-size: 0.9rem;
  color: #333;
}

.task-item.completed {
  color: #5941A9;
  text-decoration: line-through;
}
