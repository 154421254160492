.bottom-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f8f8f8;
    position: fixed;
    bottom: 0;
    max-width: 100%;
    width: 100%;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
}

.bottom-nav a {
    color: #333;
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-nav a:hover {
    color: #5941A9 !important;
}

.footer-nav-container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 4px 4px;
}

.nav-item {
    padding: 0px 4px;
}
