.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
    font-family: Arial, sans-serif;
    flex-direction: column;
}

.coming-soon-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.coming-soon-text {
    font-size: 1.2rem;
    color: #666;
}
