.top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 10px 20px;
    color: #fff;
}

.brand {
    font-size: 1.5rem;
}

.top-nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 15px;
}

.top-nav a:hover {
    color: #ddd;
}

.navbar {
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2)
}

.navbar-toggler-icon {
    background-image: none;
}

.inline-flex {
    display: inline-flex !important;
}

.search-container {
    align-items: center !important;
    justify-content: center !important;
    height: 50px;
}

.search-item {
    padding: 0px 4px;
    align-self: center;
}

.nav-container {
    max-width: 1400px;
}
