.task-calendar {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f4f4f4;
}

.calendar-header {
  background-color: #d3d3d3;
  padding: 10px;
  text-align: center;
}

.calendar-header h1 {
  margin: 0;
  font-size: 20px;
}

.view-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.view-button {
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  margin: 0 5px;
  font-size: 14px;
}

.view-button.active {
  background-color: #ccc;
}

.calendar-content {
  padding: 10px;
}

.day-view h2,
.week-view h2,
.month-view h2 {
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
}

.events {
  display: flex;
  flex-direction: column;
}

.event {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.event span {
  width: 60px;
  font-size: 14px;
}

.event-box {
  background-color: #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.calendar-day.active {
  background-color: #b0b0b0;
}

.calendar-footer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #d3d3d3;
}

.icon-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}
