.brain-dump-container {
   padding: 20px;
   max-width: 600px;
   margin: 0 auto;
   font-family: Arial, sans-serif;
}

.brain-dump-textarea {
   width: 100%;
   height: 300px;
   font-size: 16px;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   resize: vertical;
   box-sizing: border-box;
}

.brain-dump-tag-input {
   margin-top: 10px;
   display: flex;
   align-items: center;
}

.brain-dump-input {
   margin-right: 5px;
   padding: 5px;
   border: 1px solid #ccc;
   border-radius: 5px;
   flex: 1;
}

.brain-dump-button {
   padding: 5px 10px;
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s;
}

.brain-dump-button:hover {
   background-color: #0056b3;
}

.brain-dump-tags {
   margin-top: 10px;
}

.brain-dump-tag {
   display: inline-block;
   padding: 5
}
