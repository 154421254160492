.user-insights {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.insights-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.insight-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insight-card h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.insight-card p {
  font-size: 1.5rem;
  color: #007bff;
  margin: 0;
}
