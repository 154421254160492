/* BuddyFeed.css */
.buddy-feed .container {
    max-width: 100%;
}

.buddy-feed h3 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.buddy-feed .btn-primary {
    background-color: #5941A9;
    border-color: #5941A9;
}

.buddy-feed .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
}

.buddy-feed .btn-outline-success:hover {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745;
}

.buddy-feed .card {
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
