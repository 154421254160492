.user-goals {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.goals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.goal-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.goal-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.goal-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.goal-target {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 1rem;
}

.progress-container {
  margin-top: 0.5rem;
}

.progress-label {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.2rem;
}

.progress-bar {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 10px;
  background-color: #28a745;
}
