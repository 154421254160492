/* ArchivedTasks.css */
.archived-tasks .container {
    max-width: 100%;
}

.archived-tasks .task {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
}

.archived-tasks h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.archived-tasks .btn-outline-primary {
    color: #5941A9 !important;
    border-color: #5941A9 !important;
}

.archived-tasks .btn-outline-primary:hover {
    background-color: #5941A9 !important;
    color: #fff !important;
    border-color: #5941A9 !important;
}
