.browser-history {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}

h2 {
  text-align: center;
}

.file-input, .search-input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1rem;
}

.history-list {
  list-style-type: none;
  padding: 0;
}

.history-item {
  border-bottom: 1px solid #ddd;
  padding: 0.75rem 0;
}

.history-title {
  font-size: 1.1rem;
  font-weight: bold;
}

.history-url {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  overflow-wrap: anywhere;
}

.history-url:hover {
  text-decoration: underline;
}

.history-visit-time {
  font-size: 0.8rem;
  color: #666;
}

.no-history {
  text-align: center;
  color: #999;
  padding: 1rem;
}
