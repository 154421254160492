/* PublicProfile.css */
.public-profile .container {
    max-width: 100%;
}

.public-profile .card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.public-profile .text-muted {
    color: #6c757d;
    font-size: 0.9rem;
}

.public-profile .profile-details h5 {
    font-size: 1.25rem;
    margin-top: 1.5rem;
}

.public-profile .list-group-item {
    border: none;
    padding-left: 0;
}

.public-profile img.rounded-circle {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.public-profile {
    margin-bottom: 8.5rem;
}
