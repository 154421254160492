.notes-container {
   max-width: 600px;
   margin: 0 auto;
   padding: 20px;
   font-family: Arial, sans-serif;
}

.notes-textarea {
   width: 100%;
   height: 100px;
   padding: 10px;
   font-size: 16px;
   margin-top: 10px;
   margin-bottom: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   resize: vertical;
}

.add-note-button {
   padding: 10px 15px;
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-size: 16px;
   transition: background-color 0.3s;
   margin-bottom: 20px;
}

.add-note-button:hover {
   background-color: #0056b3;
}

.notes-list {
   margin-top: 20px;
}

.note-preview {
   padding: 15px;
   border: 1px solid #ccc;
   border-radius: 5px;
   background-color: #f9f9f9;
   margin-bottom: 10px;
   cursor: pointer;
   transition: background-color 0.3s;
}

.note-preview:hover {
   background-color: #e0e0e0;
}

.note-preview p {
   margin: 0;
   font-size: 16px;
   color: #333;
}
