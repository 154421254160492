.inbox-container {
   max-width: 600px;
   margin: 0 auto;
   padding: 20px;
   font-family: Arial, sans-serif;
}

.emails-list {
   border: 1px solid #ddd;
   border-radius: 5px;
   overflow: hidden;
}

.email-preview {
   padding: 15px;
   border-bottom: 1px solid #eee;
   cursor: pointer;
   transition: background-color 0.3s;
}

.email-preview:last-child {
   border-bottom: none;
}

.email-preview:hover {
   background-color: #f0f0f0;
}

.email-preview.unread {
   background-color: #eaf4fe;
}

.email-preview.read {
   background-color: #ffffff;
}

.email-subject {
   font-size: 16px;
   margin: 0;
}

.email-sender, .email-content {
   font-size: 14px;
   color: #555;
   margin: 0;
}

.email-detail {
   margin-top: 20px;
   padding: 15px;
   border: 1px solid #ddd;
   border-radius: 5px;
   background-color: #f9f9f9;
}

.close-button {
   background-color: #007bff;
   color: white;
   padding: 5px 10px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-size: 14px;
   margin-bottom: 15px;
   transition: background-color 0.3s;
}

.close-button:hover {
   background-color: #0056b3;
}

.sticky-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}
