.feed-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.notifications-list {
  list-style-type: none;
  padding: 0;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ddd;
}

.notification-content {
  flex: 1;
}

.notification-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}

.notification-message {
  font-size: 0.9rem;
  margin: 0.25rem 0;
  color: #555;
}

.notification-timestamp {
  font-size: 0.8rem;
  color: #999;
}

.dismiss-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 0.9rem;
}

.no-notifications {
  text-align: center;
  color: #666;
}
