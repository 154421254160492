body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
}

h1 {
    text-align: center;
    color: #333;
}

.settings-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.setting-group {
    margin-bottom: 20px;
}

.setting-group h2 {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1em;
}

.toggle-container label {
    color: #555;
}

.toggle-container input[type="checkbox"] {
    width: 40px;
    height: 20px;
    appearance: none;
    background-color: #ddd;
    border-radius: 10px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-container input[type="checkbox"]:checked {
    background-color: #000;
}

.toggle-container input[type="checkbox"]::after {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 0.3s;
}

.toggle-container input[type="checkbox"]:checked::after {
    transform: translateX(20px);
}

.push-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.push-button:hover {
    background-color: #0056b3;
}
