body {
    font-family: Arial, sans-serif;
}

.navbar-brand {
    font-weight: bold;
}

.hero-section {
    background-color: #007bff;
    color: white;
    text-align: center;
    padding: 50px 0;
}

.card h5 {
    font-size: 1.25em;
}

footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
}
