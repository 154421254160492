.coach-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.coach {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.coach-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.coach-name {
  font-size: 18px;
  margin: 10px 0;
  font-weight: bold;
}

.coach-rating {
  font-size: 20px;
  color: #FFD700;
}

.star {
  color: #e4e5e9;
}

.star.filled {
  color: #FFD700;
}
